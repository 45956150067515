<template>
  <div class="mod-talk">
    <el-form :inline="true" :model="dataForm" ref="DataForm">
      <el-form-item prop="keyword">
        <el-input
          v-model="dataForm.keyword"
          placeholder="输入关键字搜索"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item prop="did">
        <el-select
          v-model="dataForm.did"
          clearable
          placeholder="按部门筛选"
          @visible-change="departmentSelect"
          @change="departmentSelectChange"
          name="departmen"
        >
          <el-option
            v-for="item in departmentOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="tid">
        <el-select
          v-model="dataForm.tid"
          clearable
          placeholder="按班组筛选"
          @visible-change="teamSelect"
          @change="teamSelectChange"
        >
          <el-option
            v-for="item in teamOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="eid">
        <el-select
          v-model="dataForm.eid"
          clearable
          filterable
          placeholder="按员工筛选"
          @visible-change="employeeSelect"
          @change="employeeSelectChange"
        >
          <el-option
            v-for="item in employeeOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="talkerEid">
        <el-select
          v-model="dataForm.talkerEid"
          clearable
          filterable
          placeholder="按谈话人筛选"
          @visible-change="talkerSelect"
        >
          <el-option
            v-for="item in talkerOptions"
            :key="item.id"
            :label="item.talkerName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="getDataList()">查询</el-button>
        <el-button type="primary" @click="addOrUpdateHandle()">新增</el-button>
      </el-form-item>
    </el-form>

    <el-table
      :data="dataList"
      border
      size="medium"
      v-loading="dataListLoading"
      style="width: 100%"
    >
      <el-table-column
        label="序号"
        type="index"
        width="70"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="talkPlace"
        header-align="center"
        align="center"
        label="谈话地点"
        :show-overflow-tooltip="true"
        min-width="150"
      >
      </el-table-column>
      <el-table-column
        prop="talkDate"
        header-align="center"
        align="center"
        label="谈话时间"
        width="100"
      >
        <template slot-scope="scope">
          <div>
            {{ scope.row.talkDate ? scope.row.talkDate.split(" ")[0] : "" }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="talkerName"
        header-align="center"
        align="center"
        label="谈话人名字"
      >
      </el-table-column>
      <el-table-column
        prop="intervieweeName"
        header-align="center"
        align="center"
        label="被谈话人名字"
      >
      </el-table-column>
      <el-table-column
        prop="talkContent"
        header-align="center"
        align="center"
        label="谈话内容"
        min-width="150"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <!-- <el-table-column prop="bodyStatus" header-align="center" align="center" label="身体状况">
                <template slot-scope="scope">
                    <div>
                        {{ (scope.row.bodyStatus === 0 || scope.row.bodyStatus)?
                        bodyStatusList[scope.row.bodyStatus].name:''}}
                    </div>
                </template>
            </el-table-column>

            <el-table-column prop="isFever" header-align="center" align="center" label="是否发热">
                <template slot-scope="scope">
                    <div>

                        {{ (scope.row.isFever === 0 || scope.row.isFever)? whetherOpt[scope.row.isFever].name:''}}
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="travelMode" header-align="center" align="center" label="上岗出行的方式">
                <template slot-scope="scope">
                    <div>
                        {{ (scope.row.travelMode === 0 || scope.row.travelMode)?
                        travelModeList[scope.row.travelMode].name : ''}}
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="sleepStatus" header-align="center" align="center" label="睡眠状态">
                <template slot-scope="scope">
                    <div>
                        {{ (scope.row.sleepStatus === 0 || scope.row.sleepStatus)?
                        sleepStatusList[scope.row.sleepStatus].name : ''}}
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="isMeetRequire" header-align="center" align="center" label="是否满足要求">
                <template slot-scope="scope">
                    <div>
                        {{ (scope.row.isMeetRequire ===0 || scope.row.isMeetRequire)?
                        whetherOpt[scope.row.isMeetRequire].name:''}}
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="isKnowDriveRule" header-align="center" align="center" label="是否知道驾驶规则">
                <template slot-scope="scope">
                    <div>
                        {{ (scope.row.isKnowDriveRule === 0 || scope.row.isKnowDriveRule)?
                        whetherOpt[scope.row.isKnowDriveRule].name:''}}
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="isDrink" header-align="center" align="center" label="是否饮酒">
                <template slot-scope="scope">
                    <div>
                        {{ (scope.row.isDrink === 0 || scope.row.isDrink)? whetherOpt[scope.row.isDrink].name:''}}
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="satisfiedWork" header-align="center" align="center" label="工作满意情况">
                <template slot-scope="scope">
                    <div>
                        {{ (scope.row.isDrink === 0 || scope.row.isDrink)?
                        satisfiedWorkList[scope.row.isDrink].name:''}}
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="relation" header-align="center" align="center" label="与班组人员的关系">
                <template slot-scope="scope">
                    <div>
                        {{ (scope.row.relation === 0 || scope.row.relation)? relationList[scope.row.relation].name:''}}
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="familyIncome" header-align="center" align="center" label="家庭收入情况">
                <template slot-scope="scope">
                    <div>
                        {{ (scope.row.familyIncome === 0 || scope.row.familyIncome)?
                        familyIncomeList[scope.row.familyIncome].name:''}}
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="familyRelationChange" header-align="center" align="center" label="近期家庭关系有没有变化">
                <template slot-scope="scope">
                    <div>
                        {{ (scope.row.familyRelationChange ===0 || scope.row.familyRelationChange)?
                        whetherOpt[scope.row.familyRelationChange].name:''}}
                    </div>
                </template>

            </el-table-column> -->
      <el-table-column
        prop="createDate"
        header-align="center"
        align="center"
        label="创建时间"
        width="120"
      >
        <template slot-scope="scope">
          <div>
            {{ scope.row.createDate ? scope.row.createDate.split(" ")[0] : "" }}
          </div>
        </template>
      </el-table-column>

      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="mini"
            @click="addOrUpdateHandle(scope.row.id)"
            >查看</el-button
          >
          <el-button
            type="danger"
            size="mini"
            @click="deleteHandle(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="text-align: center; margin-top: 10px"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100, 1000, 10000]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @refreshDataList="getDataList"
    ></add-or-update>
  </div>
</template>
  
<script>
import AddOrUpdate from "./talk-add-or-update";

export default {
  data() {
    return {
      dataForm: {
        keyword: "",
        did: "",
        tid: "",
        eid: "",
        talkerEid: "",
      },

      departmentOptions: [], //部门列表选项
      teamOptions: [], //部门列表选项
      employeeOptions: [], //员工列表选项
      talkerOptions: [], // 谈话人列表选项

      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,

      // option---
      // 身体状况opt
      bodyStatusList: [
        { name: "非常健康", value: 0 },
        { name: "良好", value: 1 },
        { name: "一般", value: 2 },
        { name: "较差", value: 3 },
      ],
      // 上岗出行方式opt
      travelModeList: [
        { name: "公共交通", value: 0 },
        { name: "私家汽车", value: 1 },
        { name: "摩托", value: 2 },
        { name: "电动车", value: 3 },
        { name: "其它", value: 4 },
      ],
      // 睡眠状态opt
      sleepStatusList: [
        { name: "非常好", value: 0 },
        { name: "良好", value: 1 },
        { name: "一般", value: 2 },
        { name: "较差", value: 3 },
      ],
      // 是否opt
      whetherOpt: [
        { name: "否", value: 0 },
        { name: "是", value: 1 },
      ],
      // 工作满意情况opt
      satisfiedWorkList: [
        { name: "非常满意", value: 0 },
        { name: "比较满意", value: 1 },
        { name: "一般", value: 2 },
        { name: "不满意", value: 3 },
      ],
      // 与班组人员的关系opt
      relationList: [
        { name: "非常和谐", value: 0 },
        { name: "良好", value: 1 },
        { name: "一般", value: 2 },
        { name: "较差", value: 3 },
      ],
      // 家庭收入情况opt
      familyIncomeList: [
        { name: "非常好", value: 0 },
        { name: "良好", value: 1 },
        { name: "一般", value: 2 },
        { name: "困难", value: 3 },
      ],
    };
  },
  components: {
    AddOrUpdate,
  },
  created() {
    this.getDataList();
  },
  methods: {
    // 部门选择(打开下拉框)
    departmentSelect(e) {
      if (e) {
        this.$http.department.list({ limit: 99999, page: 1 }).then((res) => {
          this.departmentOptions = res?.data?.list;
        });
      }
    },
    // 部门选择(选择发生变化)
    departmentSelectChange(e) {
      this.dataForm.tid = "";
      this.teamOptions = [];
      this.dataForm.eid = "";
      this.employeeOptions = [];
      this.dataForm.talkerEid = "";
      this.talkOptions = [];
    },
    // 班组选择(打开下拉框)
    teamSelect(e) {
      if (e) {
        this.$http.team
          .list({ did: this.dataForm.did, limit: 99999, page: 1 })
          .then((res) => {
            this.teamOptions = res?.data?.list;
          });
      }
    },
    // 班组选择(选择发生变化)
    teamSelectChange(e) {
      this.dataForm.eid = "";
      this.employeeOptions = [];
      this.dataForm.talkerEid = "";
      this.talkOptions = [];
    },
    // 员工选择(打开下拉框)
    employeeSelect(e) {
      if (e) {
        this.$http.employee
          .list({
            did: this.dataForm.did,
            tid: this.dataForm.tid,
            limit: 99999,
            page: 1,
          })
          .then((res) => {
            this.employeeOptions = res?.data?.list;
          });
      }
    },
    // 员工选择(选择发生变化)
    employeeSelectChange(e) {
      this.dataForm.talkerEid = "";
      this.talkOptions = [];
    },

    // 谈话人选择(打开下拉框)
    talkerSelect(e) {
      if (e) {
        this.$http.talk
          .list({
            did: this.dataForm.did,
            tid: this.dataForm.tid,
            eid: this.dataForm.eid,
            limit: 99999,
            page: 1,
          })
          .then((res) => {
            this.talkerOptions = res?.data?.list;
          });
      }
    },

    // 获取数据列表
    getDataList() {
      this.dataListLoading = true;
      this.dataForm.page = this.pageIndex;
      this.dataForm.limit = this.pageSize;

      this.$http.talk.list(this.dataForm).then((data) => {
        if (data && data.code === 0) {
          this.dataList = data.data.list;
          this.totalPage = data.data.totalCount;
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
        this.dataListLoading = false;
      });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id);
      });
    },
    // 删除
    deleteHandle(id) {
      // 全局mixin混入事件；val输入的备注(then：确认按钮后的操作；catch：取消后的操作)
      this.remarksDelete()
        .then((val) => {
          this.$http.talk
            .delete({
              id,
              remarks: val,
            })
            .then((data) => {
              if (data && data.code === 0) {
                this.$message({
                  message: data.msg,
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    this.getDataList();
                  },
                });
              } else {
                this.$message.error(data.msg);
              }
            });
        })
        .catch(() => {});
    },
  },
};
</script>
  